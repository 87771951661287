import React from 'react';
import {
    setWorkplaceSavingsContributeYou,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import MonthlyContribution from "../../MonthlyContribution/MonthlyContribution";

const WorkplaceSavingsYouContributeComp = ( props ) => {
    const onChange = val => {
        props.setWorkplaceSavingsContributeYou(val);
        props.onChange()
    }

    return (
        < MonthlyContribution
            total={props.workplaceSavingsContribute}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        workplaceSavingsContribute: state.savingsAndInvestments.you.workplaceSavings.contributeMonthly
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setWorkplaceSavingsContributeYou : val => dispatch(setWorkplaceSavingsContributeYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsYouContributeComp);