import React from 'react';
import {
    setWorkplaceSavingsContributeSpouse,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import MonthlyContribution from "../../MonthlyContribution/MonthlyContribution";

const WorkplaceSavingsSpouseContributeComp = ( props ) => {
    const onChange = val => {
        props.setWorkplaceSavingsContributeSpouse(val);
        props.onChange()

    };

    return (
        < MonthlyContribution
            total={props.workplaceSavingsContribute}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        workplaceSavingsContribute: state.savingsAndInvestments.spouse.workplaceSavings.contributeMonthly
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setWorkplaceSavingsContributeSpouse : val => dispatch(setWorkplaceSavingsContributeSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsSpouseContributeComp);