import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import WorkplaceSavingsYouContributeComp
    from "../../../../components/ContentRow/Step3/WorkplaceSavings/WorkplaceSavingsContribute/WokplaceSavingsYouContributeComp";
import WorkplaceSavingsSpouseContributeComp
    from "../../../../components/ContentRow/Step3/WorkplaceSavings/WorkplaceSavingsContribute/WokplaceSavingsSpouseContributeComp";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const WorkplaceSavingsContribute = ( props ) => {
    const titles = {
        layoutTitle: 'WORKPLACE SAVINGS',
        sectionTitle: 'How much do you plan to contribute monthly?',
    };

    const title = "Monthly contribution in workplace savings";

    const [next, setNext] = useState(retirementCalcLinks.properties);

    const nextLink = useCallback(() => {
        if(
            props.you.workplaceSavings.total !== 0
            || props.you.workplaceSavings.contributeMonthly!== 0
            || props.spouse.workplaceSavings.total!== 0
            || props.spouse.workplaceSavings.contributeMonthly!== 0
        )  {
            return retirementCalcLinks.workplaceSavingsLegislation;
        }else if(props.you.lockedIn.exist || props.spouse.lockedIn.exist) {
            return retirementCalcLinks.lockedInHave;
        }else if(props.you.tfsa.exist || props.spouse.tfsa.exist){
            return retirementCalcLinks.tfsaHave;
        }else if(props.you.nonRegistered.exist || props.spouse.nonRegistered.exist){
            return retirementCalcLinks.nonRegisteredHave;
        }else {
            return retirementCalcLinks.properties;
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink())
    }, [props, nextLink]);

    const onChange = () => {
        setNext(nextLink())
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(5/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.you.workplaceSavings.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <WorkplaceSavingsYouContributeComp onChange={onChange}/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse &&  props.spouse.workplaceSavings.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <WorkplaceSavingsSpouseContributeComp onChange={onChange} />
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsContribute);